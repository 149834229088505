import axios from "axios"
import {placeCaretAt} from "../../public/tinymce";

const axiosIns = axios.create()

axiosIns.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    return Promise.reject(error)
})

export const uploadVideo = (access_token, open_id, form_data, onUploadProgress) => {
    const url = `https://open.douyin.com/api/douyin/v1/video/upload_video/?open_id=${open_id}`
    return axiosIns.post(url, form_data, {
        headers: {
            "content-type": "multipart/form-data",
            "access-token": access_token
        },
        onUploadProgress
    })
}

//抖音开放平台-图片上传
export const uploadImage = (access_token, open_id, file, onUploadProgress) => {
    const formData = new FormData()
    formData.append("image", file)
    const url = `https://open.douyin.com/api/douyin/v1/video/upload_image/?open_id=${open_id}`
    return axiosIns.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "access-token": access_token
        },
        onUploadProgress
    })
}

//抖音开放平台-分片上传
export const douyinPartUpload = (access_token, open_id, upload_id, chunk, part_number) => {
    const formData = new FormData()
    formData.append("video", chunk)
    const url = `https://open.douyin.com/api/douyin/v1/video/upload_video_part/?open_id=${open_id}&part_number=${part_number}&upload_id=${upload_id}`
    return axiosIns.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "access-token": access_token
        }
    })
}


